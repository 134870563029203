import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';

export default {
	data() {
		return {
			flashAlert: {
				show: false,
				msg: '',
			},
			imagePath: process.env.VUE_APP_IMAGE_URL,
		};
	},
	computed: {
		...mapState(['wl']),
		...mapGetters(['isLoggedIn']),
		...mapGetters(['loggedinUserDetails']),
		...mapGetters(['wishListCount']),
		...mapGetters(['getCurrentCartTypes']),
		...mapGetters(['getCurrentShoppingLists']),
		...mapGetters(['getCurrentHolHolidays']),
		...mapGetters(['getMetaDetails']),
		...mapGetters(['getColorBubbles']),
		...mapGetters(['quoteDetails']),
	},
	methods: {
		...mapMutations({ addToWL_s: 'addToWL' }),
		...mapActions(['fetchMetaDetails']),
		...mapActions(['fetchColorBubbles']),

		tryAddToWL(prodId) {
			if (!this.isLoggedIn) {
				return 0;
			}

			if (prodId in this.wl) {
				return -1;
			}

			this.addToWL_s(prodId);
			return 1;
		},

		triggerFlashAlert(msg, level = 'error') {
			if (this.embed) {
				this.emitFlashAlert(msg, level);
			} else {
				this.showFlashAlert(msg, level);
			}
		},

		showFlashAlert(msg, level = 'error') {
			this.flashAlert = {
				show: true,
				level,
				msg,
			};
		},

		emitFlashAlert(msg, level = 'error') {
			this.emitter.emit('flash', { msg, level });
		},

		formatToDb(dateObj) {
			return dateObj.getFullYear() + '-' + String(dateObj.getMonth() + 1).padStart(2, '0') + '-' + dateObj.getDate();
		},

		formatDateFromStr(datestr) {
			let dateObj = new Date(datestr);
			return dateObj.getFullYear() + '-' + String(dateObj.getMonth() + 1).padStart(2, '0') + '-' + dateObj.getDate();
		},

		formatDateWithZeroFromStr(datestr) {
			var isFirefox = typeof InstallTrigger !== 'undefined';
			let userAgentString = navigator.userAgent;
			var isChrome = userAgentString.indexOf('Chrome') > -1;
			let isSafari = userAgentString.indexOf('Safari') > -1;

			if (isFirefox || isChrome) {
				let dateObj = new Date(datestr);
				return dateObj.getFullYear() + '-' + String(dateObj.getMonth() + 1).padStart(2, '0') + '-' + String(dateObj.getDate()).padStart(2, '0');
			}
			if (isSafari) {
				let actualDate = datestr.split(' ');
				let dateStrArr = actualDate[0].split('-');
				let yy = parseInt(dateStrArr[0]);
				let mm = parseInt(dateStrArr[1]);
				let dd = parseInt(dateStrArr[2]);
				mm = String(mm).padStart(2, '0');
				dd = String(dd).padStart(2, '0');
				let datWithTime = yy + '-' + mm + '-' + dd + 'T00:00:00';
				let dateObj = new Date(datWithTime);
				return dateObj.getFullYear() + '-' + String(dateObj.getMonth() + 1).padStart(2, '0') + '-' + String(dateObj.getDate()).padStart(2, '0');
			} else {
				let actualDate = datestr.split(' ');
				let dateStrArr = actualDate[0].split('-');
				let yy = parseInt(dateStrArr[0]);
				let mm = parseInt(dateStrArr[1]);
				let dd = parseInt(dateStrArr[2]);
				let dateObj = new Date(yy, mm, dd, 0, 0, 0);
				return dateObj.getFullYear() + '-' + String(dateObj.getMonth()).padStart(2, '0') + '-' + String(dateObj.getDate()).padStart(2, '0');
			}
		},

		formatDateWithDash(dateStr) {
			let dateObj = new Date(dateStr);
			return String(dateObj.getMonth() + 1).padStart(2, '0') + '-' + dateObj.getDate() + '-' + dateObj.getFullYear();
		},

		formatDateWithDashNew(dateStr) {
			dateStr = this.appendTimeForBrowser(dateStr);
			let dateObj = new Date(dateStr);
			return String(dateObj.getMonth() + 1).padStart(2, '0') + '-' + dateObj.getDate() + '-' + dateObj.getFullYear();
		},

		formatDate(dateStr) {
			let dateObj = new Date(dateStr);
			return String(dateObj.getMonth() + 1).padStart(2, '0') + '/' + dateObj.getDate() + '/' + dateObj.getFullYear();
		},

		formatDateWithPaddedZeros(dateStr) {
			let dateObj = new Date(dateStr);
			if (isNaN(dateObj.getFullYear()) || isNaN(dateObj.getMonth()) || isNaN(dateObj.getDate())) {
				return '';
			}
			return String(dateObj.getMonth() + 1).padStart(2, '0') + '-' + String(dateObj.getDate()).padStart(2, '0') + '-' + dateObj.getFullYear();
		},

		appendTime(date) {
			return date.split(' ').length > 1 ? date : `${date} 00:00:00`;
		},

		appendTimeForBrowser(date) {
			if (date.indexOf('00:00:00') < 0) {
				let userAgentString = navigator.userAgent;
				let isSafari = userAgentString.indexOf('Safari') > -1;

				if (isSafari) {
					return `${date}T00:00:00`;
				} else {
					return `${date} 00:00:00`;
				}
			}

			return date;
		},

		getDefaultImage(productType) {
			let images = localStorage.getItem('productTypes');
			images = JSON.parse(images);
			if (productType == '' || productType == null) {
				return '';
			}
			for (var i = 0; i < images.length; i++) {
				if (images[i]['id'] == productType) return images[i]['default_image'];
			}

			return '';
		},

		clearCartComments() {
			let allCurrentCarts = JSON.parse(localStorage.getItem('cart'));
			let exceptCart = [];
			for (const progId in allCurrentCarts) {
				exceptCart.push(parseInt(progId));
			}
			let site_settings = JSON.parse(localStorage.getItem('site_settings'));

			let cartsType = [
				parseInt(site_settings.miami_direct_boxlots_id),
				parseInt(site_settings.live_local_id),
				parseInt(site_settings.dutch_direct_boxlots_id),
				parseInt(site_settings.farm_direct_boxlots_id),
				parseInt(site_settings.flower_futures_id),
				parseInt(site_settings.hardgoods_id),
				parseInt(site_settings.mass_markets_id),
				parseInt(site_settings.weekly_specials_id),
			];
			let difference = [];
			if (exceptCart.length > 0) {
				difference = cartsType.filter((x) => !exceptCart.includes(x)).concat(exceptCart.filter((x) => !cartsType.includes(x)));
			} else {
				difference = cartsType;
			}

			let cartsCommentExists = '';
			difference.forEach((cartId) => {
				cartsCommentExists = localStorage.getItem('cartCommentsDetails_' + cartId);
				if (cartsCommentExists) localStorage.removeItem('cartCommentsDetails_' + cartId);

				cartsCommentExists = localStorage.getItem('cartPoDetails_' + cartId);
				if (cartsCommentExists) localStorage.removeItem('cartPoDetails_' + cartId);
			});
		},

		getBreakdown(breakdownJson, box_in_unit) {
			let breakdowns = JSON.parse(breakdownJson);
			let str = '';
			let unit_for_bd = '';
			if (breakdowns.length > 0) {
				str =
					'<table class="tbl_breakdown text-left" style="color:#fff"><thead><tr><td colspan=2 align="center" style="color:#fff">Breakdown – Subject to Change</td></tr></thead><tbody><tr style="border: 1px solid #fff"><td align="left" style="border: 1px solid #fff; color:#fff; padding: 0px;" width=70%>Product</td><td align=right width=30% style="color:#fff; padding: 0px;">Units</td></tr>';

				breakdowns.forEach((breakdown) => {
					unit_for_bd = breakdown.bunches * breakdown.stemsBunch;
					if (breakdown.stemsBunch > 1) box_in_unit = 'Stem';
					else box_in_unit = 'Bunch';

					unit_for_bd = unit_for_bd + ' ' + box_in_unit;
					str =
						str +
						'<tr style="border: 1px solid #fff; width:70%"><td align="left" style="border: 1px solid #fff; color: #fff; padding: 0px;" >' +
						breakdown.productDescription +
						'</td><td align="right" style="color: #fff; padding: 0px;">' +
						unit_for_bd +
						'</td></tr>';
				});

				str = str + '</tbody></table>';
			}
			return str;
		},
		
		getMaxQuantity(product) {
			if (product.order_max_quantity > 0 && product.order_max_quantity < product.qty) {
				return product.order_max_quantity;
			}
			return product.qty;
		},
	},
};