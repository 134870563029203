// import axios from "axios";
import { mapGetters } from "vuex";
export default {
    data() {
        return {
            refreshing: false,
            registration: null,
            updateExists: false,
        }
    },
    computed: {
        ...mapGetters(["isLoggedIn"]),
    },
    created() {
        document.addEventListener('swUpdated', this.updateAvailable, { once: true });

        navigator.serviceWorker.addEventListener('controllerchange', () => {
            if (this.refreshing) return;
            this.refreshing = true;

            this.clearCacheAndReload();
        });
    },
    beforeDestroy() {
        document.removeEventListener('swUpdated', this.updateAvailable)
    },
    methods: {
        installPWA() {
            this.installEvent.prompt();
            this.installEvent.userChoice.then((choice) => {
                this.dismissPWAPrompt(); // Hide the prompt once the user's clicked
                if (choice.outcome === "accepted") {
                    // this.pwaInstalled();
                } else {
                    // Do something additional if the user declined
                }
            });
        },
        isIOS() {
            return (/ipad|iphone|ipod/.test(navigator.userAgent.toLowerCase()));
		},
		isInStandaloneMode() {
			return ('standalone' in window.navigator) && (window.navigator.standalone);
		},
        isChrome() {
            return (window.navigator.userAgent.toLowerCase().indexOf("chrome") > -1);
        },
        isFirefox() {
            return (navigator.userAgent.toLowerCase().indexOf('firefox') > -1);
        },
		isSafari() {
            return (!!navigator.userAgent.toLowerCase().match(/version\/[\d.]+.*safari/) && !window.MSStream);
		},
        isEdge() {
            return (navigator.userAgent.toLowerCase().indexOf("edge/") > -1 || window.navigator.userAgent.toLowerCase().indexOf("edg/") > -1);
        },
        isOpera() {
            return (navigator.userAgent.toLowerCase().match(/opera|opr\//));
        },
        updateAvailable(event) {
            this.registration = event.detail;
            this.updateExists = true;
        },
        refreshApp() {
            if (!this.registration || !this.registration.waiting) {
                console.log('No service worker registration available');
                return;
            }
            
            this.registration.waiting.postMessage({ type: 'SKIP_WAITING' });
            this.updateExists = false;

            this.clearCacheAndReload();
        },
        clearCacheAndReload() {
            if ('caches' in window) {
                caches.keys().then(names => {
                    names.forEach(name => {
                        caches.delete(name);
                    });
                });
            }

            setTimeout(() => {
                this.refreshing = true;
                window.location.reload(true);
            }, 100);
        },
        // pwaInstalled() {
        //     if (this.isLoggedIn) {
        //         axios
        //             .get(process.env.VUE_APP_API_BASE + "auth/saveInstalledPwa", {
        //                 headers: {
        //                 "Content-type": "application/json;multipart/form-data",
        //                 Authorization: "Bearer " + localStorage.getItem("token"),
        //                 },
        //             })
        //             .catch((err) => {
        //                 console.log('An error occurred while saving token. ', err);
        //             });
        //     }
        // },
    }
}