import axios from "axios";
import { mapGetters } from "vuex";
export default {
    computed: {
        ...mapGetters(["loggedinUserDetails","isLoggedIn","canViewLoyalty"]),
    },
    methods: {
        fetchCustomerLoyaltyInfo() {
            if (this.isLoggedIn) {
                axios
                    .get(process.env.VUE_APP_API_BASE + "auth/getCustomerLoyaltyInfo", {
                        headers: {
                        "Content-type": "application/json;multipart/form-data",
                        Authorization: "Bearer " + localStorage.getItem("token"),
                        },
                    }).then((response) => {
                        let customer = this.loggedinUserDetails;
                        if (response.data.customer) {
                            customer.can_enroll_loyalty = response.data.customer.can_enroll_loyalty
                            customer.is_enrolled_loyalty = response.data.customer.is_enrolled_loyalty
                            customer.loyalty_status = response.data.customer.loyalty_status
                            localStorage.setItem("loggedinUser", JSON.stringify(customer));
                            if (this.canViewLoyalty) {
                                setTimeout(() => {
                                    let elements = document.querySelectorAll('.petals-education');
                                    if (elements.length > 0) {
                                        elements.forEach((element) => {
                                            element.classList.remove('d-none');
                                        })
                                    }
                                }, 1000);
                            }
                        }
                    }).catch((err) => {
                        console.log('An error occurred while retriving the customer information. ', err);
                    });
            }
        },
    }
}