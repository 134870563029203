export default {
	methods: {
		prepareCutoffObj(cutOffs, availDate) {
			let cutOffDelDate = availDate['delivery_date'];
			cutOffDelDate = cutOffDelDate.split('-');
			// cutOffDelDate[2] = cutOffDelDate[2].replace(/^0+/, '');
			cutOffDelDate = cutOffDelDate.join('-');
			if (!(cutOffDelDate in cutOffs)) {
				cutOffs[cutOffDelDate] = [];
			}
			cutOffs[cutOffDelDate].push({
				programId: availDate['program_id'],
				carrierId: availDate['shipping_method_id'],
				shippingDate: availDate['cutoff'],
				programName: availDate['program_name'],
				shippingMethodName: availDate['shipping_method_name'],
				cutoff: availDate['cutoff_time'],
				programTimezone: availDate['program_timezone'],
				branchTimezone: availDate['branch_timezone'],
			});
		},

		prepareCutoffObjForMassMarkets(cutOffs, availDate) {
			let cutOffDelDate = availDate['delivery_date'];
			let found = false
			cutOffDelDate = cutOffDelDate.split('-');
			// cutOffDelDate[2] = cutOffDelDate[2].replace(/^0+/, '');
			cutOffDelDate = cutOffDelDate.join('-');
			if (!(cutOffDelDate in cutOffs)) {
				cutOffs[cutOffDelDate] = [];
			
				// pushing in array
				cutOffs[cutOffDelDate].push({
					programId: availDate['program_id'],
					carrierId: availDate['shipping_method_id'],
					shippingDate: availDate['cutoff'],
					programName: availDate['program_name'],
					shippingMethodName: availDate['shipping_method_name'],
					cutoff: availDate['cutoff_time'],
					programTimezone: availDate['program_timezone'],
					branchTimezone: availDate['branch_timezone'],
					origin: availDate['mmt_origin_name'],
				});
			} else {
				cutOffs[cutOffDelDate].forEach(element => {
					if ( element.carrierId === availDate['shipping_method_id']
							&& element.shippingDate === availDate['cutoff']
							&& element.cutoff === availDate['cutoff_time'] ) {
							found = true
							element.origin = element.origin + ', ' + availDate['mmt_origin_name']
					}	
				});
				if (!found) {
					cutOffs[cutOffDelDate].push({
						programId: availDate['program_id'],
						carrierId: availDate['shipping_method_id'],
						shippingDate: availDate['cutoff'],
						programName: availDate['program_name'],
						shippingMethodName: availDate['shipping_method_name'],
						cutoff: availDate['cutoff_time'],
						programTimezone: availDate['program_timezone'],
						branchTimezone: availDate['branch_timezone'],
						origin: availDate['mmt_origin_name']
					});
				}
			}
		},

		prepareCutoffObjForDutchDirectBoxlots(cutOffs, availDate) {
			let cutOffDelDate = availDate['delivery_date'];
			let found = false
			cutOffDelDate = cutOffDelDate.split('-');
			// cutOffDelDate[2] = cutOffDelDate[2].replace(/^0+/, '');
			cutOffDelDate = cutOffDelDate.join('-');
			if (!(cutOffDelDate in cutOffs)) {
				cutOffs[cutOffDelDate] = [];
			
				// pushing in array
				cutOffs[cutOffDelDate].push({
					programId: availDate['program_id'],
					carrierId: availDate['shipping_method_id'],
					shippingDate: availDate['cutoff'],
					programName: availDate['program_name'],
					shippingMethodName: availDate['shipping_method_name'],
					cutoff: availDate['cutoff_time'],
					programTimezone: availDate['program_timezone'],
					branchTimezone: availDate['branch_timezone'],
					farmName: availDate['farm_name'],
				});
			} else {
				cutOffs[cutOffDelDate].forEach(element => {
					if ( element.carrierId === availDate['shipping_method_id']
							&& element.shippingDate === availDate['cutoff']
							&& element.cutoff === availDate['cutoff_time'] ) {
							found = true
							element.farmName = element.farmName + ', ' + availDate['farm_name']
					}	
				});
				if (!found) {
					cutOffs[cutOffDelDate].push({
						programId: availDate['program_id'],
						carrierId: availDate['shipping_method_id'],
						shippingDate: availDate['cutoff'],
						programName: availDate['program_name'],
						shippingMethodName: availDate['shipping_method_name'],
						cutoff: availDate['cutoff_time'],
						programTimezone: availDate['program_timezone'],
						branchTimezone: availDate['branch_timezone'],
						farmName: availDate['farm_name']
					});
				}
			}
		},
	},
};
