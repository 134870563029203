import axios from "axios";
import { mapGetters } from "vuex";
export default {
    computed: {
        ...mapGetters(["isLoggedIn"]),
    },
    created() {
        window.addEventListener('appinstalled', this.installed);
    },
    methods: {
        installed() {
            if (this.isLoggedIn) {
                axios
                    .get(process.env.VUE_APP_API_BASE + "auth/saveInstalledPwa", {
                        headers: {
                        "Content-type": "application/json;multipart/form-data",
                        Authorization: "Bearer " + localStorage.getItem("token"),
                        },
                    }).then((response) => {
                        if (response.data.device) {
                            let deviceInfo = {
                                device_type: response.data.device.type,
                                device_browser: response.data.device.browser,
                                operating_system: response.data.device.os,
                            };
                            this.$gtag.event("app_installed", deviceInfo);
                        }
                    }).catch((err) => {
                        console.log('An error occurred while saving the installed PWA. ', err);
                    });
            }
        },
    }
}