import axios from "axios";
import { pwa } from "@/mixins";
import { mapGetters } from 'vuex';

export default {
	mixins: [pwa],
	computed: {
        ...mapGetters(["isLoggedIn"]),
    },
	mounted() {
		var localEmitter = this.emitter;
		this.$OneSignal.User.PushSubscription.addEventListener("change", e => {
			if (e.current.id != '' && e.current.id != undefined) {
				axios
					.post(
						process.env.VUE_APP_API_BASE + "auth/notificationSubcriptionChange",
						{
							token: e.current.id,
							service: 'onesignal',
							status: e.current.optedIn
						},
						{
							headers: {
								"Content-type": "application/json",
								Authorization: "Bearer " + localStorage.getItem("token"),
							},
						}).then((response) => {
							localEmitter.emit('displayFlash', {
								message: response.data.message,
								level: 'success',
							});
						}).catch(() => {
							localEmitter.emit('displayFlash', {
								message: 'Oops, something went wrong. Please try again later!',
								level: 'error',
							});
						}
					)
			}
		})
	},
}